import globalHeader from "@/components/global-header/global-header.vue";
import sectionContact from "@/components/section-contact/section-contact.vue";

import articlesMixin from "../articles-mixin";

import ElementGithub from "./element-github/element-github.vue";
import ElementHeader from "./element-header/element-header.vue";
import ElementList from "./element-list/element-list.vue";
import ElementParagraph from "./element-paragraph/element-paragraph.vue";
import ElementImage from "./element-image/element-image.vue";
import ElementDownload from "./element-download/element-download.vue";
import ElementBoxTimeEffect from "./element-box-time-effect/element-box-time-effect.vue";
import ElementBoxChat from "./element-box-chat/element-box-chat.vue";
import ElementBoxBlue from "./element-box-blue/element-box-blue.vue";
import ElementBoxPink from "./element-box-pink/element-box-pink.vue";

// @vue/component
export default {
  components: {
    ElementGithub,
    ElementHeader,
    ElementList,
    ElementParagraph,
    ElementImage,
    ElementDownload,
    ElementBoxTimeEffect,
    ElementBoxChat,
    ElementBoxBlue,
    ElementBoxPink,
    globalHeader,
    sectionContact,
  },
  mixins: [articlesMixin],
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  computed: {
    article() {
      return this.articles.find((article) => article.slug === this.slug);
    },
  },
};
