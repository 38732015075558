import globalHeader from "@/components/global-header/global-header.vue";
import sectionContact from "@/components/section-contact/section-contact.vue";

import articlesMixin from "../articles-mixin";

export default {
  components: {
    globalHeader,
    sectionContact,
  },
  mixins: [articlesMixin],
  computed: {
    filteredArticles() {
      return this.articles.filter((i) => {
        const date = i.publishDate.match(/(\d+)/g)
        const publishDate = new Date(date[2], date[1] - 1, date[0])
        return publishDate <= new Date()
      })
    },
  },
};
