import loadTestingWithLocustIo from "../../../public/blog/load-testing-with-locust-io/article.json";
import implementingASupportTeam from "../../../public/blog/implementing-a-support-team/article.json";
import storyOf21SecondsToKnow from "../../../public/blog/story-of-21-seconds-to-know/article.json";
import manyFormatsManyApproaches from "../../../public/blog/many-formats-many-approaches/article.json";
import progressiveWebAppsVsNativeWebApps from "../../../public/blog/progressive-web-apps-vs-native-web-apps/article.json";
import promptEngineering from "../../../public/blog/prompt-engineering-personalisation-of-hotel-search-with-chat-gpt/article.json";
import challengesOfIntegration from "../../../public/blog/challenges-of-integration-with-flight-providers-in-getaway/article.json"
import automationOfHotel from "../../../public/blog/automation-of-hotel-and-accommodation-database-downloads/article.json"
import missionDeployment from "../../../public/blog/mission-deployment-of-a-flight-provider-to-getaway/article.json"
import handlingLive from "../../../public/blog/handling-live-events-traffic-with-aws-infrastructure/article.json"

export default {
  data() {
    return {
      articles: [
        handlingLive,
        missionDeployment,
        automationOfHotel,
        challengesOfIntegration,
        promptEngineering,
        progressiveWebAppsVsNativeWebApps,
        manyFormatsManyApproaches,
        storyOf21SecondsToKnow,
        implementingASupportTeam,
        loadTestingWithLocustIo
      ],
    };
  },
};
