import { render, staticRenderFns } from "./blog-articles.vue?vue&type=template&id=a431dda8"
import script from "./blog-articles.js?vue&type=script&lang=js&external"
export * from "./blog-articles.js?vue&type=script&lang=js&external"
import style0 from "./blog-articles.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports